import React from 'react'
import UserDashboard from '../components/UserDashboard'
import { graphql } from "gatsby"
import PrivateRoute from '../components/private-route'

const Dashboard = ({data, pageContext}) => {
  let products = data.products.edges
  let hardwareKits = data.hardwareKits.edges
  return(
    <PrivateRoute path="/dashboard" component={UserDashboard} fsOnly='false'
    data={data}
    products={products}
    hardwareKits={hardwareKits} />
  )
}

export default Dashboard

export const query = graphql`
query dashboardQuery {
  swatches: allFile(filter: {sourceInstanceName: {eq: "swatches"}}) {
    edges {
      node {
        childMarkdownRemark {
          fileAbsolutePath
          image {
            relativePath
            id
            childImageSharp {
              gatsbyImageData
            }
          }
          frontmatter {
            smart_code
            image
            image_dimensions
          }
        }
      }
    }
  }
  hardwareKits: allFile(filter: {sourceInstanceName: {eq: "hardware-kits"}}) {
    edges {
      node {
        childMarkdownRemark {
          fileAbsolutePath
          frontmatter {
            model
            short_description
            contents
            product_class
            net_price {
              currency
              value
            }
          }
        }
      }
    }
  }
  products: allFile(filter: {sourceInstanceName: {eq: "products"}}) {
    edges {
      node {
        childMarkdownRemark {
          frontmatter {
            smart_code
            image
            image_dimensions
            model
            short_description
            contents
            product_class
            net_price {
              currency
              value
            }
            product_name
            configurator_id
            external_documents {
              document_title
              document_url
              document_type
            }
            product_name
            product_class
            product_name_override
            configurator_id
            smart_code
            default_image
            default_configuration
            qc_status
            product_category
            website_link
            external_documents {
              document_url
              document_title
              document_type
              smart_code_restriction {
                smart_code
                position
              }
            }
            dimension_override_index
            dimension_overrides {
              dimensions
              smart_codes_selected {
                position
                smart_code
              }
            }
            description_overrides {
              smart_description
              position
              smart_codes_selected {
                position
                smart_code
              }
            }
            pricing_options {
              description
              model
              weight
              modified_standard
              default_quantity
              smart_code_multiplier
              hide_from_price_list
              base_configuration
              net_price {
                currency
                value
                pricing_schedule
              }
              smart_codes_selected {
                position
                smart_code
              }
            }
            hardware_kits {
              hardware_kit
              description
              default_quantity
              smart_code_restriction {
                position
                smart_code
              }
            }
          }
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
  productConfigs : allFile(filter: {sourceInstanceName: {eq: "product-configurations"}}) {
    edges {
      node {
        id
        childMarkdownRemark {
          frontmatter {
            dimension_override_index
            description_overrides {
              position
              smart_codes_selected {
                position
                smart_code
              }
              smart_description
            }
            configurator_id
            dimension_overrides {
              dimensions
              smart_codes_selected {
                position
                smart_code
              }
            }
            lights_off_triggers {
              smart_code
              smart_code_position
            }
            shadow_id
            shadow_off_id
            shadows {
              label
              configurator_id
              smart_code_restriction {
                position
                smart_code
              }
            }
            uses_light
            configurator_components {
              conditional_link_position
              conditional_link_value
              configurator_id
              description_overrides {
                parent_id
                smart_code_position
                smart_description
              }
              dimensions
              display_dimensions
              hide_selector
              label
              limits {
                parent_id
                smart_code_options {
                  configurator_id
                  smart_code
                }
                smart_code_position
              }
              link_to_3did
              minimum_height
              options_label
              secondary_selector
              show_color_picker
              smart_code
              smart_code_position
              smart_description
              sort_override
              use_swatches
              reset_camera
              options {
                conditional_link_position
                conditional_link_value
                configurator_id
                description_overrides {
                  parent_id
                  smart_code_position
                  smart_description
                }
                dimensions
                display_dimensions
                hide_selector
                label
                limits {
                  parent_id
                  smart_code_options {
                    configurator_id
                    smart_code
                  }
                  smart_code_position
                }
                link_to_3did
                minimum_height
                options_label
                secondary_selector
                show_color_picker
                smart_code
                smart_code_position
                smart_description
                sort_override
                use_swatches
                reset_camera
                options {
                  conditional_link_position
                  conditional_link_value
                  configurator_id
                  description_overrides {
                    parent_id
                    smart_code_position
                    smart_description
                  }
                  dimensions
                  display_dimensions
                  hide_selector
                  label
                  limits {
                    parent_id
                    smart_code_options {
                      configurator_id
                      smart_code
                    }
                    smart_code_position
                  }
                  link_to_3did
                  minimum_height
                  options_label
                  secondary_selector
                  show_color_picker
                  smart_code
                  smart_code_position
                  smart_description
                  sort_override
                  use_swatches
                  reset_camera
                  options {
                    conditional_link_position
                    conditional_link_value
                    configurator_id
                    description_overrides {
                      parent_id
                      smart_code_position
                      smart_description
                    }
                    dimensions
                    display_dimensions
                    hide_selector
                    label
                    limits {
                      parent_id
                      smart_code_options {
                        configurator_id
                        smart_code
                      }
                      smart_code_position
                    }
                    link_to_3did
                    minimum_height
                    options_label
                    secondary_selector
                    show_color_picker
                    smart_code
                    smart_code_position
                    smart_description
                    sort_override
                    use_swatches
                    reset_camera
                    options {
                      conditional_link_position
                      conditional_link_value
                      configurator_id
                      description_overrides {
                        parent_id
                        smart_code_position
                        smart_description
                      }
                      dimensions
                      display_dimensions
                      hide_selector
                      label
                      limits {
                        parent_id
                        smart_code_options {
                          configurator_id
                          smart_code
                        }
                        smart_code_position
                      }
                      link_to_3did
                      minimum_height
                      options_label
                      secondary_selector
                      show_color_picker
                      smart_code
                      smart_code_position
                      smart_description
                      sort_override
                      use_swatches
                      reset_camera
                      options {
                        conditional_link_position
                        conditional_link_value
                        configurator_id
                        description_overrides {
                          parent_id
                          smart_code_position
                          smart_description
                        }
                        dimensions
                        display_dimensions
                        hide_selector
                        label
                        limits {
                          parent_id
                          smart_code_options {
                            configurator_id
                            smart_code
                          }
                          smart_code_position
                        }
                        link_to_3did
                        minimum_height
                        options_label
                        secondary_selector
                        show_color_picker
                        smart_code
                        smart_code_position
                        smart_description
                        sort_override
                        use_swatches
                        reset_camera
                        options {
                          conditional_link_position
                          conditional_link_value
                          configurator_id
                          description_overrides {
                            parent_id
                            smart_code_position
                            smart_description
                          }
                          dimensions
                          display_dimensions
                          hide_selector
                          label
                          limits {
                            parent_id
                            smart_code_options {
                              configurator_id
                              smart_code
                            }
                            smart_code_position
                          }
                          link_to_3did
                          minimum_height
                          options_label
                          secondary_selector
                          show_color_picker
                          smart_code
                          smart_code_position
                          smart_description
                          sort_override
                          use_swatches
                          reset_camera
                          options {
                            conditional_link_position
                            conditional_link_value
                            configurator_id
                            description_overrides {
                              parent_id
                              smart_code_position
                              smart_description
                            }
                            dimensions
                            display_dimensions
                            hide_selector
                            label
                            limits {
                              parent_id
                              smart_code_options {
                                configurator_id
                                smart_code
                              }
                              smart_code_position
                            }
                            link_to_3did
                            minimum_height
                            options_label
                            secondary_selector
                            show_color_picker
                            smart_code
                            smart_code_position
                            smart_description
                            sort_override
                            use_swatches
                            reset_camera
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`
