import React, { Component } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ArchiveIcon from '@mui/icons-material/Archive'
import EditProjectDetails from './edit-project-details'
import ProjectPDF from '../ProjectPDF'
import { pdf } from '@react-pdf/renderer'
import { getPricingForProject } from '../../utils/pricing'
import { deleteProject, updateProject, duplicateProject } from '../../utils/project'
import { isBrowser, navigate } from '../../utils/misc'
import DownloadIcon from '../../styles/img/download.svg'
import EditIcon from '../../styles/img/edit.svg'
import DuplicateIcon from '../../styles/img/duplicate.svg'
import DeleteIcon from '../../styles/img/delete.svg'
import ArchivedIcon from '../../styles/img/archived-icon.svg'
import PricingAvailableIcon from '../../styles/img/pricing-available-icon.svg'
import RequestPricingIcon from '../../styles/img/request-pricing-icon.svg'
import UnderReviewIcon from '../../styles/img/under-review-icon.svg'

class ProjectItem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      anchorEl: null,
      deleteDialogOpen: false,
      editDialogOpen: false,
      archiveDialogOpen: false,
    }
  }

  componentDidMount(){

  }

  clickMenu = (e) => {
    this.setState({
      anchorEl: e.currentTarget
    })
  }

  handleClose = () => {
    this.setState({
      anchorEl: null
    })
  }

  handleDelete = () => {
    this.props.handleSetLoading(true)
    deleteProject(this.props.project._id)
    .then(function(data){
      this.setState({
        deleteDialogOpen: false,
        anchorEl: null
      }, function(){
        this.props.getProjects()
      })
    }.bind(this))
    .catch(function(err){
      console.log(err)
    })
  }

  handleDialogClose = () => {
    this.setState({
      deleteDialogOpen: false,
      editDialogOpen: false,
      anchorEl: null
    })
  }

  handleProjectUpdate = (e) => {
    this.setState({
      project: e.currentTarget.value
    }, function(){
      console.log(this.state.project)
    })
  }

  handleSave = () => {
    let projectUpdate = {...this.state.project}
    projectUpdate.name = this.state.projectName
    updateProject(projectUpdate)
    .then(function(data){
      this.setState({
        editDialogOpen: false,
        anchorEl: null
      }, function(){
        this.props.getProjects()
      })
    }.bind(this))
    .catch((err)=>{
      console.log(err)
    })
  }

  handleDuplicate = () => {
    let project = {...this.props.project}
    project.name = 'Copy of ' + project.name
    this.props.handleSetLoading(true)
    duplicateProject(project)
    .then(function(data){
      this.setState({
        anchorEl: null
      }, function(){
        this.props.getProjects()
      })
    }.bind(this))
    .catch((err)=>{
      console.log(err)
    })
  }

  downloadPDF = () => {
    this.props.handleSetLoading(true)
    this.handleDialogClose()
    if(isBrowser){
      getPricingForProject(this.props.project, this.props.products, this.props.hardwareKits)
      .then(function(pricing){
        let fileName = this.props.project.name.toLowerCase().replaceAll(' ','-')
        const projectPDF = <ProjectPDF
          project={pricing}
          />
        pdf(projectPDF).toBlob()
        .then(function(blob){
          this.props.handleSetLoading(false)
          var pdfURL = window.URL.createObjectURL(blob)
          let tempLink = document.createElement('a')
          tempLink.href = pdfURL
          tempLink.setAttribute('download', fileName + '.pdf')
          tempLink.click()
        }.bind(this))
      }.bind(this))
    }
  }

  handleArchiveProject = () => {
    this.props.handleSetLoading(true)
    this.props.project.status = 'Archived'
    updateProject(this.props.project)
    .then(data=>{
      this.props.getProjects()
      this.setState({archiveDialogOpen: false})
    })
  }

  handleNavigateToProject = () => {
    if(isBrowser){
      navigate('/project/'+this.props.project.id)
    }
  }

  handleToggleSelected = (e) => {
    this.props.handleSelectProject(this.props.project._id, e)
  }

  render() {
    let project = this.props.project
    let cityState = []
    if(project.city){
      cityState.push(project.city)
    }
    if(project.state){
      cityState.push(project.state)
    }
    let location = cityState.join(', ') + ' ' + project?.zip
    location = location.trim()
    let projectStatusClass = 'status-active'
    let status = project.status
    let statusIcon = RequestPricingIcon
    if(this.props.project.locked){
      projectStatusClass = 'status-submitted'
      if(status === 'Archived'){
        projectStatusClass = 'status-archived'
        statusIcon = ArchivedIcon
      }
    }
    let disableDelete = false
    if(status === 'Complete'){
      status = 'Pricing Available'
      projectStatusClass  = 'status-pricing-available'
      disableDelete = true
      statusIcon = PricingAvailableIcon
    }
    if(status === 'Submitted'){
      status = 'Under Review'
      disableDelete = true
      statusIcon = UnderReviewIcon
    }
    if(status === 'In Progress'){
      status = 'Request Pricing'
    }
    return(
      <div className='project-row'>
        <div className='project-name'>
          <div style={{
            border: 'none',
            padding: '5px 0px',
            backgroundColor: 'transparent',
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'column',
            gap: '.5rem',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          }}>
            <div>{project.name}</div>
            <div style={{fontSize: ".75rem"}}>
             {'Rev. ' + new Date(project.updated).toLocaleDateString() + ' ' + new Date(project.updated).toLocaleTimeString() }
            </div>
          </div>
        </div>
        <div className='project-location'>
          {location}
        </div>
        <div className='project-status'>
          <button className={'status ' + projectStatusClass}
          title={status}
          onClick={this.handleNavigateToProject}>
            <div className="hidden-md hidden-lg hidden-xl">
            <img src={statusIcon} alt={status} />
            </div>
            <div className="hidden-sm hidden-xs">{status}</div>
          </button>
        </div>
        <div className='project-actions'>
          <IconButton onClick={this.downloadPDF} title="Download Overview">
          <img src={DownloadIcon} alt='Download Overview' />
          </IconButton>
          <IconButton onClick={()=>{
            this.setState({editDialogOpen: true})
          }} title="Edit Project" disabled={disableDelete}>
          <img src={EditIcon} alt='Edit Project' />
          </IconButton>
          <IconButton onClick={this.handleDuplicate} title="Duplicate Project">
          <img src={DuplicateIcon} alt='Duplicate Project' />
          </IconButton>
          {disableDelete &&
              <IconButton onClick={()=>{this.setState({archiveDialogOpen: true})}} title="Archive Project">
              <ArchiveIcon sx={{color: '#666666'}} />
              </IconButton>
          }
          {!disableDelete &&
              <IconButton onClick={()=>{this.setState({deleteDialogOpen:true})}} title="Delete Project"
              disabled={disableDelete}>
              <img src={DeleteIcon} alt='Delete Project' />
              </IconButton>
          }
          <div className="hidden-md hidden-lg hidden-xl">
            <IconButton id="basic-button"
            aria-controls={this.state.anchorEl ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={this.state.anchorEl ? 'true' : undefined}
            onClick={this.clickMenu}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={this.state.anchorEl}
              open={this.state.anchorEl ? true : false}
              onClose={this.handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={()=>{
              this.setState({editDialogOpen: true, anchorEl: false})
              }}disabled={disableDelete}>Edit Project</MenuItem>
              <MenuItem onClick={this.handleDuplicate}>Duplicate Project</MenuItem>
              {disableDelete &&
                  <MenuItem onClick={()=>{this.setState({archiveDialogOpen: true, anchorEl: null})}} >Archive Project</MenuItem>
              }
              {!disableDelete && 
                  <MenuItem onClick={()=>{
                    this.setState({deleteDialogOpen:true, anchorEl: null})
                  }} disabled={disableDelete}>Delete Project</MenuItem>
              }
            </Menu>
          </div>
        </div>
        <Dialog
          open={this.state.deleteDialogOpen}
          className='status-alert'
          onClose={()=>{this.setState({deleteDialogOpen: false})}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete this Project?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Warning: this action is not recoverable. Proceed with caution.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>{this.setState({deleteDialogOpen: false})}}>
            Cancel
            </Button>
            <Button onClick={this.handleDelete} variant="contained">
            Delete
            </Button>
          </DialogActions>
        </Dialog>
        <EditProjectDetails 
            open={this.state.editDialogOpen}
            project={project}
            onClose={()=>{this.setState({editDialogOpen: false})}}
            handleSetLoading={this.props.handleSetLoading}
            onComplete={()=>{
              this.setState({editDialogOpen: false})
              this.props.getProjects()
            }}
        />
        <Dialog
          open={this.state.archiveDialogOpen}
          onClose={()=>{this.setState({archiveDialogOpen: false})}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Archive this Project?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This will remove the project from your current active list. Don't worry, you can always view archived projects using the project filter.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>{this.setState({archiveDialogOpen: false})}}>
            Cancel
            </Button>
            <Button onClick={this.handleArchiveProject} variant="contained">
            Archive
            </Button>
          </DialogActions>
        </Dialog>
        <button onClick={this.handleNavigateToProject} title={"Go to " + project?.name}>
        </button>
      </div>
    )
  }
}

export default ProjectItem
