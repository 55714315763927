import React, { Component } from "react"
import Layout from "../layout-mobile"
import {
  Typography,
  IconButton,
  Button,
  Snackbar,
  Alert,
  TextField,
  Menu,
  MenuItem,
} from "@mui/material"
import ProjectFormNew from "../ProductViewer/project-form-new"
import ProjectItem from "./project-item"
import LoadingBox from "../loading-box"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import styled from "styled-components"
import { getAllProjects, deleteProjectMultiple } from "../../utils/project"
import { getUserInfo } from "../../utils/user"
import { isBrowser, navigate } from "../../utils/misc"

class UserDashboard extends Component {
  constructor(props) {
    super(props)
    this.newProjectButton = React.createRef()
    this.state = {
      myProjects: [],
      error: "",
      successMsg: "",
      newProjectFormVisible: false,
      loading: true,
      panel1: true,
      panel2: true,
      userInfo: null,
      filterSubmitted: "",
      filterKeyword: "",
      sortType: "chronoDesc",
      selectMenu: null,
      deleteDialogOpen: false,
      selectAll: false,
      showFilter: false,
      showSearch: false,
      showSort: false,
      deleteMode: false,
      filterEl: null,
      sortEl: null,
    }
    if (isBrowser) {
      this.getProjects()
    }
  }

  componentDidMount() {
    if (isBrowser) {
      this.getProjects()
    }
  }

  setError = err => {
    let message = err?.toString()
    if (!message) {
      message = "Unspecified Error"
    }
    this.setState({
      error: message,
      loading: false,
      deleteMode: false,
    })
  }

  selectProject = (projectID, e) => {
    let projects = this.state.myProjects
    let projectSelected = projects.find(object => {
      return object._id === projectID
    })
    if (!projectSelected.locked) {
      projectSelected.selected = e.target.checked
    }
    this.setState({
      myProjects: projects,
      selectMenu: null,
    })
  }

  selectAllProjects = selected => {
    let projects = this.state.myProjects
    projects.forEach((item, i) => {
      if (!item.locked) {
        item.selected = selected
      }
    })
    this.setState({
      myProjects: projects,
      selectMenu: null,
      selectAll: selected,
    })
  }

  handleGetUserInfo = () => {
    this.setState({
      loading: true,
    })
    getUserInfo()
      .then(info => {
        this.setState({
          userInfo: info,
          loading: false,
        })
      })
      .catch(err => {
        this.setState({
          errMsg: err.toString(),
          loading: false,
        })
      })
  }

  getProjects = archived => {
    let user = null
    getUserInfo()
      .then(function (userInfo) {
        user = userInfo
        return getAllProjects(archived)
      })
      .then(
        function (data) {
          data.sort((a, b) => {
            if (a.created > b.created) {
              return -1
            } else if (a.created < b.created) {
              return 1
            } else {
              return 0
            }
          })
          this.setState({
            myProjects: data,
            userInfo: user,
            loading: false,
          })
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log(err)
          this.setError(err)
        }.bind(this)
      )
  }

  handleCloseError = () => {
    this.setState({
      error: "",
    })
  }

  handleFilterSearch = e => {
    this.setState({
      filterKeyword: e.target.value,
    })
  }

  toggleFilterState = filterVal => {
    this.setState({
      loading: true,
    })
    if (this.state.filterSubmitted === filterVal) {
      filterVal = ""
    }
    let getArchived = false
    if (filterVal === "archived") {
      getArchived = true
    }
    this.getProjects(getArchived)
    this.setState({
      filterSubmitted: filterVal,
      filterEl: null,
      showFilter: false,
    })
  }

  changeSortType = sortType => {
    let newSort = sortType
    if (newSort === this.state.sortType) {
      if (newSort.includes("Desc")) {
        newSort = newSort.replace("Desc", "")
      } else {
        newSort = newSort + "Desc"
      }
    }
    this.setState({
      sortType: newSort,
      sortEl: null,
    })
  }

  handleSetLoading = loading => {
    this.setState({
      loading: loading,
    })
  }

  clickMenu = e => {
    this.setState({
      selectMenu: e.currentTarget,
    })
  }

  handleCloseSelect = () => {
    this.setState({
      selectMenu: null,
    })
  }

  handleCloseSuccess = () => {
    this.setState({
      successMsg: "",
    })
  }

  handleDialogClose = () => {
    this.setState({
      deleteDialogOpen: false,
    })
  }

  toggleDeleteMode = () => {
    this.setState({
      deleteMode: !this.state.deleteMode,
    })
  }

  toggleSelected = projectId => {
    let myProjects = { ...this.state.myProjects }
    let findProject = myProjects.find(item => {
      return item._id === projectId
    })
    if (findProject) {
      findProject.selected = true
    }
    this.setState({
      myProjects: myProjects,
    })
  }

  handleDeleteMultiple = () => {
    this.setState({
      loading: true,
    })
    let projectIds = this.state.myProjects.map(item => {
      if (item.selected) {
        return item._id
      } else {
        return null
      }
    })
    deleteProjectMultiple({ ids: projectIds })
      .then(data => {
        this.setState({
          deleteDialogOpen: false,
          successMsg: "Complete",
          deleteMode: false,
        })
        this.getProjects()
      })
      .catch(err => {
        this.setError(err)
      })
  }

  toggleSearchVisible = () => {
    this.setState({
      showSearch: !this.state.showSearch,
    })
  }

  render() {
    let projects = null
    let sortText = "Newest"
    let sortAlphaText = "Alphabetical Ascending"
    let projectsList = [...this.state.myProjects]
    if (projectsList?.length) {
      if (this.state.sortType === "alpha") {
        projectsList.sort((a, b) => a.name.localeCompare(b.name))
        sortText = "Alphabetical"
      }
      if (this.state.sortType === "alphaDesc") {
        projectsList.sort((a, b) => b.name.localeCompare(a.name))
        sortText = "Alphabetical"
        sortAlphaText = "Alphabetical Descending"
      }
      if (this.state.sortType === "chronoUpdatedDesc") {
        projectsList.sort((a, b) => b.updated.localeCompare(a.updated))
        sortText = "Newest"
      }
      if (this.state.sortType === "chronoUpdated") {
        projectsList.sort((a, b) => a.updated.localeCompare(b.updated))
        sortText = "Oldest"
      }
      if (this.state.sortType === "chronoDesc") {
        projectsList.sort((a, b) => b.created.localeCompare(a.created))
        sortText = "Newest"
      }
      if (this.state.sortType === "chrono") {
        projectsList.sort((a, b) => a.created.localeCompare(b.created))
        sortText = "Oldest"
      }
      projects = projectsList.map((project, i) => {
        let returnProject = true
        if (this.state.filterSubmitted) {
          if (
            this.state.filterSubmitted === "submitted" &&
            project.status !== "Submitted"
          ) {
            returnProject = false
          }
          if (
            this.state.filterSubmitted === "active" &&
            project.status !== "In Progress"
          ) {
            returnProject = false
          }
          if (
            this.state.filterSubmitted === "complete" &&
            project.status !== "Complete"
          ) {
            returnProject = false
          }
          if (
            this.state.filterSubmitted === "archived" &&
            project.status !== "Archived"
          ) {
            returnProject = false
          }
        } else {
          if (project.status === "Archived") {
            returnProject = false
          }
        }
        if (this.state.filterKeyword && returnProject) {
          let filterKeyword = this.state.filterKeyword.toLowerCase()
          if (
            !project?.name?.toLowerCase()?.includes(filterKeyword) &&
            !project?.zip?.toLowerCase()?.includes(filterKeyword)
          ) {
            returnProject = false
          }
        }
        if (returnProject) {
          return (
            <ProjectItem
              project={project}
              key={project._id}
              getProjects={this.getProjects}
              products={this.props.products}
              hardwareKits={this.props.hardwareKits}
              handleSetLoading={this.handleSetLoading}
              handleSelectProject={this.selectProject}
              handleGetUserInfo={this.handleGetUserInfo}
              deleteMode={this.state.deleteMode}
              handleToggleSelected={() => {
                this.toggleSelected(project._id)
              }}
              userInfo={this.state.userInfo}
            />
          )
        } else {
          return null
        }
      })
      projects.unshift(
        <div key="dashboard-headers" className="dashboard-headers">
          <div>Project Title</div>
          <div>Location</div>
          <div>Status</div>
          <div>Actions</div>
        </div>
      )
    } else {
      projects = (
        <Typography
          sx={{
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <strong>It’s a bit empty in here...</strong>
          Tap “Create a Project” to get started.
        </Typography>
      )
    }

    let projectsSelected = 0
    for (var i = 0; i < this.state.myProjects.length; i++) {
      if (this.state.myProjects[i].selected) {
        projectsSelected++
      }
    }

    let functions = (
      <>
        <Button variant="contained" onClick={this.toggleSearchVisible}>
          Search
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            this.setState({
              newProjectFormVisible: true,
            })
          }}
          disabled={this.state.deleteMode}
          className="new-project-button"
        >
          Create a Project
        </Button>
      </>
    )
    return (
      <Layout
        titleText="Projects"
        withSortButtons={true}
        showMenu={true}
        path={this.props.path}
        toolFunctions={functions}
      >
        {this.state.loading && <LoadingBox />}
        <SearchEditAddSection>
          <IconButton
            onClick={e => {
              this.setState({
                filterEl: e.currentTarget,
              })
            }}
          >
            Filter
            <KeyboardArrowDownIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={this.state.filterEl}
            open={this.state.filterEl ? true : false}
            onClose={() => {
              this.setState({ filterEl: null })
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={e => {
                this.toggleFilterState("active")
              }}
              className={
                this.state.filterSubmitted === "active" ? "active" : ""
              }
            >
              &nbsp;Active
            </MenuItem>
            <MenuItem
              onClick={e => {
                this.toggleFilterState("submitted")
              }}
              className={
                this.state.filterSubmitted === "submitted" ? "active" : ""
              }
            >
              &nbsp;Under Review
            </MenuItem>
            <MenuItem
              onClick={e => {
                this.toggleFilterState("complete")
              }}
              className={
                this.state.filterSubmitted === "complete" ? "active" : ""
              }
            >
              &nbsp;Pricing Available
            </MenuItem>
            <MenuItem
              onClick={e => {
                this.toggleFilterState("archived")
              }}
              className={
                this.state.filterSubmitted === "archived" ? "active" : ""
              }
            >
              &nbsp;Archived
            </MenuItem>
          </Menu>
          <IconButton
            onClick={e => {
              this.setState({
                sortEl: e.currentTarget,
              })
            }}
          >
            {sortText}
            {this.state.sortType.includes("Desc") ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowUpIcon />
            )}
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={this.state.sortEl}
            open={this.state.sortEl ? true : false}
            onClose={() => {
              this.setState({ sortEl: null })
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              className={
                ["chrono", "chronoDesc"].includes(this.state.sortType)
                  ? "active"
                  : ""
              }
              onClick={e => {
                this.changeSortType("chronoDesc")
              }}
            >
              &nbsp;Chronological Created{" "}
              {this.state.sortType === "chrono" ? "Ascending" : "Descending"}
            </MenuItem>
            <MenuItem
              className={
                ["chronoUpdated", "chronoUpdatedDesc"].includes(
                  this.state.sortType
                )
                  ? "active"
                  : ""
              }
              onClick={e => {
                this.changeSortType("chronoUpdatedDesc")
              }}
            >
              &nbsp;Chronological Edited{" "}
              {this.state.sortType === "chronoUpdated"
                ? "Ascending"
                : "Descending"}
            </MenuItem>
            <MenuItem
              className={
                ["alpha", "alphaDesc"].includes(this.state.sortType)
                  ? "active"
                  : ""
              }
              onClick={e => {
                this.changeSortType("alpha")
              }}
            >
              &nbsp;{sortAlphaText}
            </MenuItem>
          </Menu>
          {this.state.showSearch && (
            <form
              className="search-edit-create-form"
              onSubmit={e => {
                e.preventDefault()
              }}
            >
              <TextField
                variant="filled"
                placeholder="Search projects by title or ZIP"
                value={this.state.filterKeyword}
                inputProps={{
                  autoComplete: "off",
                }}
                onChange={this.handleFilterSearch}
              />
            </form>
          )}
        </SearchEditAddSection>
        <section className="dashboard-projects">{projects}</section>
        {this.state.deleteMode && (
          <section
            style={{
              height: "71px",
              position: "fixed",
              bottom: "0px",
              left: "0px",
              right: "0px",
              zIndex: "2001",
              width: "100%",
              top: "unset",
              backgroundColor: "#fff",
            }}
          >
            <Button
              style={{
                width: "100%",
                backgroundColor: "#e31837",
                color: "#fff",
                height: "100%",
                borderRadius: "0px",
                justifyContent: "center",
              }}
              disabled={!projectsSelected}
              onClick={() => {
                this.setState({
                  deleteDialogOpen: true,
                })
              }}
            >
              Delete Selected
            </Button>
          </section>
        )}

        <Snackbar
          open={this.state.error ? true : false}
          autoHideDuration={6000}
          onClose={this.handleCloseError}
        >
          <Alert
            elevation={6}
            variant="filled"
            severity="error"
            onClose={this.handleCloseError}
          >
            {this.state.error ? this.state.error : ""}
          </Alert>
        </Snackbar>
        <Snackbar
          open={this.state.successMsg ? true : false}
          autoHideDuration={6000}
          onClose={this.handleCloseSuccess}
        >
          <Alert
            elevation={6}
            variant="filled"
            severity="success"
            onClose={this.handleCloseSuccess}
          >
            {this.state.successMsg ? this.state.successMsg : ""}
          </Alert>
        </Snackbar>
        <ProjectFormNew
          open={this.state.newProjectFormVisible}
          handleSetLoading={this.handleSetLoading}
          onClose={() => {
            this.setState({ newProjectFormVisible: false })
          }}
          handleCancel={() => {
            this.setState({ newProjectFormVisible: false })
          }}
          onComplete={project => {
            this.setState({ newProjectFormVisible: false })
            navigate("/project/" + project._id)
          }}
        />
      </Layout>
    )
  }
}

export default UserDashboard

const SearchEditAddSection = styled.section`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 10px;
  form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
    margin-top: 10px;
    .MuiTextField-root {
      width: calc(100%);
      line-height: 3rem;
      div:before {
        border-bottom: none;
      }
      input {
        border-bottom: none;
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }
  }
  button {
    height: 3rem;
    line-height: 3rem;
    display: flex;
    gap: 5px;
    flex-direction: row;
    background-color: transparent;
  }
  .edit-button {
    width: 100px;
    background-color: #c8c8c8;
    color: #000;
  }
  .new-project-button {
    width: 40%;
  }
`
