import React, { Component } from 'react'
import {
  Button,
  TextField,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { updateProject } from '../../utils/project'
import { getTaxRate } from '../../utils/ziptax'
const isBrowser = typeof window !== `undefined`

class FormUpdateProject extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  render() {
    return(
      <form
        className="entry-form"
        method="post"
        onSubmit={event => {
          this.props.handleSubmit(event)
        }}
      >
        <div className="required">
            <FormLabel>Project Name</FormLabel>
            <TextField
            required
            id="name"
            name="name"
            value={this.props?.project?.name || ''}
            placeholder="Untitled"
            variant="outlined"
            onChange={this.props.handleUpdate}
            />
        </div>
        <div className="required">
            <FormLabel>Project Zip/Postal Code</FormLabel>
            <TextField
             required
             id="zip"
             name="zip"
             value={this.props?.project?.zip || ''}
             placeholder="00000"
             variant="outlined"
             onChange={this.props.handleUpdate}
             onBlur={this.props.handleZipUpdate}
             className='location-input'
             sx={{
               width: '100%'
             }}
            />
        </div>
        {this.props.location &&
          <div>
            <p><strong>Project Location: </strong></p>
            <p>{this.props.location}</p>
          </div>
        }
      </form>
    )
  }
}


class EditProjectDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      project: this.props.project,
      errMsg: '',
      disableSubmit: false,
      zipInfo: null
    }
  }

  componentDidMount(){

  }

  handleUpdate = (e) => {
    let project = this.state.project
    project[e.target.name] = e.target.value
    this.setState({
      project: project
    })
  }

  handleZipUpdate = e => {
    getTaxRate(e.target.value)
    .then(zipInfo=>{
      this.setState({
        zipInfo: zipInfo
      })
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.handleSetLoading(true)
    if(isBrowser){
      let project = {...this.state.project}
      if(this.state.zipInfo){
        project.city = this.state.zipInfo.geoCity
        project.state = this.state.zipInfo.geoState
      }
      updateProject(project)
      .then((data) => {
        this.props.onComplete()
      })
      .catch((err) => {
        this.setState({
          errMsg: err.message
        })
      })
    }
  }

  render() {
    let location = []
    if(this.props.project.city){
      location.push(this.props.project.city)
    }
    if(this.props.project.state){
      location.push(this.props.project.state)
    }
    if(this.state.zipInfo){
      location = [ ]
      location.push(this.state.zipInfo.geoCity)
      location.push(this.state.zipInfo.geoState)
    }
    return(
        <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton onClick={this.props.onClose}>
              <CloseIcon />
          </IconButton>
          <span>{"Edit Project"}</span>
        </DialogTitle>
        <DialogContent>
        <FormUpdateProject handleUpdate={this.handleUpdate}
          handleSubmit={this.handleSubmit}
          project={this.state.project}
          location={location.join(', ')}
          handleZipUpdate={this.handleZipUpdate}
          handleCancel={this.props.handleCancel} />
          <DialogContentText id="alert-dialog-description">
          {this.state.errMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>
          Cancel
          </Button>
          <Button onClick={this.handleSubmit} variant="contained">Save</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default EditProjectDetails
